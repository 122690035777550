<template>
  <div id="EditUser" class="row mx-0">
    <div class="row mx-0">
      <div id="header" class="col-sm-12 col-md-12 color-bg-4">
        <Header
                :name="user.fullname"
                /> 
      </div>
      <div class="col-sm-12 col-md-12">
        <div id="content" class="row color-bg-4">
          <div id="" class="">
            <SideMenu :indexsec="13"/>
          </div>
    
          <div id="stn-edituser" class="stn_edituserAdd">
            <form
              @submit.prevent="
                submit(
                  data.name, 
                  data.color1,
                   data.color2,
                    data.color3,
                     data.color4,
                      data.color5,
                )
              "
            >
            <div class="div_EProduct">
              <p>EDITAR PERFIL</p>
              </div>

              

              <div class="row mx-0 row-one">
               
             
                 <div class="EPF1_C1centrado">
                  <label class="labelN color-1" for="inputN"
                    >Nombre</label
                  >
                  <b-form-input
                  pattern="[a-zA-ZñÑáéíóúÁÉÍÓÚ0-9\s]{2,254}"
                    v-model="data.name"
                    id="inputN"
                  />
                </div>
                
              </div>

               <div class="row mx-0 row-two row-colors">
                <div class="EPF2_C1">
                  <label class="labelc1 color-1" for="inputc1">Color 1</label>
                  <b-form-input
                  
                    v-model="data.color1"
                    required
                    id="inputc1"
                    type="color"
                  />
                </div>
                <div class="">
                  <label class="labelc2 color-1" for="inputc2">Color 2</label>
                  <b-form-input
                  
                    v-model="data.color2"
                    required
                    id="inputc2"
                    type="color"
                  />
                </div>
                 <div class="">
                  <label class="labelc3 color-1" for="inputc3">Color 3</label>
                  <b-form-input
                  
                    v-model="data.color3"
                    required
                    id="inputc3"
                    type="color"
                  />
                </div>
                
                <div class="EPF1_C1">
                  <label class="labelc4 color-1" for="inputc4">Color 4</label>
                  <b-form-input
                  
                    v-model="data.color4"
                    required
                    id="inputc4"
                    type="color"
                  />
                </div>
                 <div class="">
                  <label class="labelc5 color-1" for="inputc5">Color 5</label>
                  <b-form-input
                  
                    v-model="data.color5"
                    required
                    id="inputc5"
                    type="color"
                  />
                </div>
              </div>

        
            
               <div class="row mx-0 row-one">
                <div class="div-error" v-if="this.status=='error'">
                    <p class="msg-error">{{this.msg}}</p>
                </div>

              </div>

              <div class="btn-form">
                <b-btn
                  variant="outline-secondary"
                  class="editar-modal "
                  type="submit"
                  >GUARDAR</b-btn
                >
                <b-modal
                  id="modal-editar"
                  ref="modal-editar"
                  no-close-on-backdrop
                  centered
                  hide-footer
                  hide-header
                >
                  <div class="message-modal">
                    <img :src="IconSuccess" class="success" /><br />
                    <p class="text-modal">El perfil de color se edito correctamente</p>
                  </div>
                  <div class="">
                    <b-button class="btn-modal" @click="editarOtro()"
                      >ACEPTAR</b-button
                    >
                    <b-button
                      type="button"
                      aria-label="Close"
                      class="close-modal"
                      @click="salir()"
                      >×</b-button
                    >
                  </div>
                </b-modal>
              </div>
            </form>
         
           
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import SideMenu from '../../components/SideMenu';
import logo from '../../assets/cimarrones/logocima_2.png';
import IconSuccess from "../../assets/Vector.png";
import legrafica from "../../assets/Legrafica.png";
import categorias from '../../assets/categorias.png';
import usuarios from '../../assets/usuarios.png';
import productos from '../../assets/productos.png';
import Header from '../../components/Header';
import { mapActions } from "vuex";
export default {
  name: "EditType",
    components: {
    Header,
    SideMenu,
  },
  data() {
    return {
      status: "",
      msg: "",
      msg: "success",
      logo: logo,
      legrafica: legrafica,
      categorias: categorias,
      usuarios: usuarios,
      productos: productos,
      IconSuccess: IconSuccess,
    };
  },async beforeMount() {
        let token = await this.$store.dispatch("admin/getToken");
        this.$store.dispatch("admin/decode", token);
    },
  created() {
    let id = this.$route.params.id;
    let payload = {
      id: id,
      option: "profile",
    };
    this.getInfoById(payload);
    
  },
  computed: {
    data() {
      return this.$store.getters["main/data"];
    },
     url() {
      return this.$store.getters["main/baseURL"];
    },
    user() {            
        return this.$store.getters['admin/getIdentity'];
    },
  },
  methods: {
    ...mapActions("main", ["getInfoById"]),
    wait: function () {
      setTimeout(() => this.$router.go(), 200);
    },
    submit: async function (name, color1, color2, color3, color4, color5) {
       let id = this.$route.params.id;
     

        this.status=''
        this.msg=''
             
      
      let data = {
        id: id,
        name: name,
        color1:color1,
        color2:color2,
        color3:color3,
        color4:color4,
        color5:color5,
        
      };

       
      let result = await  this.$store.dispatch("main/editItem",  {option:'profile', item:data});
         
          if(result.status=='error'){
            this.status='error'
            this.msg= result.message
          }else{ // success
              this.showModal();
          }
      
      

    },
    editarOtro: function () {
      this.wait();
    },
    salir: function () {
       this.$router.push("/administrador").catch((err) => {});
    },
   
    showModal() {
      this.$refs["modal-editar"].show();
    },
   
  },
};
</script>


